<template>
  <div>
    <h5>Videos</h5>
    <div class="row" v-if="!loading">
      <div class="col-md-4" v-for="(video, i) in videos" :key="`video-${i}`">
        <div class="card">
          <router-link
            :to="{
              name: 'moderator.academy.videos.show',
              params: { video_id: video.id },
            }"
          >
            <video-thumbnail :video="video" />
          </router-link>
          <div class="px-2 my-2">
            <router-link
              :to="{
                name: 'moderator.academy.videos.show',
                params: { video_id: video.id },
              }"
              >#{{ video.sort }} {{ video.title }}</router-link
            >
          </div>
        </div>
      </div>
      <div class="col-12" v-if="videos.length == 0">
        <div class="alert alert-warning">There is no data to display</div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      videos: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/moderator/academy/${this.$route.params.week_id}/videos`)
        .then((response) => {
          this.videos = response.data.videos;
          this.loading = false;
        });
    }
  },
};
</script>
